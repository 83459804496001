/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    code: "code",
    pre: "pre",
    blockquote: "blockquote",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "transformers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#transformers",
    "aria-label": "transformers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Transformers"), "\n", React.createElement(_components.p, null, "Change the behaviour of xstyled's primitives."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.p, null, "Transformers are an advanced feature of styled that give you the opportunity to configure the underlayer of the system."), "\n", React.createElement(_components.h2, {
    id: "use-rem-as-default-unit",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#use-rem-as-default-unit",
    "aria-label": "use rem as default unit permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Use ", React.createElement(_components.code, null, "rem"), " as default unit"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://engageinteractive.co.uk/blog/em-vs-rem-vs-px"
  }, "Using ", React.createElement(_components.code, null, "rem"), " is always better for accessibility"), ", but it is very hard to implement a complete design using ", React.createElement(_components.code, null, "rem"), " unit because most of design tools are not ready for it. For example, Sketch displays pixels instead of ", React.createElement(_components.code, null, "rem"), "."), "\n", React.createElement(_components.p, null, "xstyled solves this problem by automatically transforming your ", React.createElement(_components.code, null, "px"), " into ", React.createElement(_components.code, null, "rem"), ". It uses a base of ", React.createElement(_components.code, null, "1rem = 16px"), ", the default on modern browsers."), "\n", React.createElement(_components.p, null, "To use ", React.createElement(_components.code, null, "rem"), " instead of ", React.createElement(_components.code, null, "px"), ", configure transformers in your theme. All unitless properties will be transformed into ", React.createElement(_components.code, null, "rem"), " instead of ", React.createElement(_components.code, null, "px"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import styled, { x, ThemeProvider, rpxTransformers } from '@xstyled/...'\n\nconst theme = {\n  transformers: {\n    ...rpxTransformers,\n  },\n}\n\nconst Title = styled.h1`\n  font-size: 20;\n`\n\nexport default () => (\n  <ThemeProvider theme={theme}>\n    <Title>Hello</Title>\n    <x.div m={16} />\n  </ThemeProvider>\n)\n\n// The style of the `Title` will be `font-size: 1.25rem` instead of `font-size: 20px`.\n// The style of the `Box` will be `margin: 1rem` instead of `margin: 16px`.\n")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "If you want to use ", React.createElement(_components.code, null, "px"), " again, just specify it as string: ", React.createElement(_components.code, null, "font-size: 12px"), "."), "\n"), "\n", React.createElement(_components.h3, {
    id: "change-the-default-ratio",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#change-the-default-ratio",
    "aria-label": "change the default ratio permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Change the default ratio"), "\n", React.createElement(_components.p, null, "By default, xstyled uses the default value of ", React.createElement(_components.code, null, "1rem = 16px"), ". It is the default base root font size in all browsers. You can configure it in ", React.createElement(_components.code, null, "theme.settings.rootFontSize"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "export const theme = {\n  settings: {\n    // <x.div m={20} /> will be transformed to <x.div m=\"1rem\" />\n    rootFontSize: 20,\n  },\n}\n")), "\n", React.createElement(_components.h2, {
    id: "use-custom-transformers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#use-custom-transformers",
    "aria-label": "use custom transformers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Use custom transformers"), "\n", React.createElement(_components.p, null, "All theme getters are configurable through transformers. You can for example write a transformers that transforms colors on the fly."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import styled, { ThemeProvider } from '@xstyled/styled-components'\nimport { lighten } from 'polished'\n\nconst theme = {\n  transformers: {\n    color: (value) => {\n      const matches = value.match(/(.*)\\.light/)\n      if (!matches) return value\n      return lighten(0.2, matches[1])\n    },\n  },\n}\n\nexport default () => (\n  <ThemeProvider theme={theme}>\n    <Box backgroundColor=\"black.light\" />\n  </ThemeProvider>\n)\n\n// The background of the box will be \"black\" lightened with 0.2\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
